import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/browser';

export default defineNuxtPlugin(() => {
  Sentry.init({
    dsn: 'https://4462aebee33aea13890086acfef9cecc@o4506031065333760.ingest.us.sentry.io/4508534594797568',
    tracesSampleRate: 0.0,
    integrations: [],
  });

  return {
    provide: {
      Sentry,
    },
  };
});